import React from 'react';

export default function Header() {
  const handleMailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.href = 'mailto:ponce@tetsuwan.com';
  };

  return (
    <header className="flex justify-between items-center mb-12 mobile:mb-6 tablet:mb-8 laptop:mb-12 relative z-20">
      <div className="flex items-center">
        <img 
          src="https://createsiteai.b-cdn.net/static_website/tetsuwan_scientific/Full%20Tetsuone%20Logo%20(White).svg" 
          alt="Tetsuwan Scientific Logo" 
          className="h-24 w-auto mobile:h-16 tablet:h-20 laptop:h-24"
        />
      </div>
      <a
        href="mailto:ponce@tetsuwan.com"
        onClick={handleMailClick}
        className="bg-[#30e4ac] text-white px-8 py-1.5 rounded-full flex items-center justify-center mobile:px-5 mobile:py-1 tablet:px-6 tablet:py-1 laptop:px-8 laptop:py-1.5"
      >
        <img
          src="https://createsiteai.b-cdn.net/static_website/tetsuwan_scientific/mail_icon.png"
          alt="Email"
          className="w-9 h-9 object-contain mobile:w-7 mobile:h-7 tablet:w-8 tablet:h-8 laptop:w-9 laptop:h-9"
        />
      </a>
    </header>
  );
}