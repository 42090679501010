import React from 'react';
import TextGrid from '../../TextGrid';

export default function HeroTitle() {
  return (
    <TextGrid>
      <h1 className="text-[3.65rem] mobile:text-[2rem] tablet:text-[2.8rem] laptop:text-[3.65rem] mb-4 tracking-tighter leading-tight">
        A new era of science,
      </h1>
      <h2 className="text-[calc(var(--size-reduction)*theme(fontSize.5xl)+var(--desktop-font-increase))] mobile:text-[calc(var(--size-reduction)*theme(fontSize.2xl))] tablet:text-[calc(var(--size-reduction)*theme(fontSize.3xl))] laptop:text-[calc(var(--size-reduction)*theme(fontSize.5xl)+var(--desktop-font-increase))] mb-16 mobile:mb-8 tablet:mb-12 laptop:mb-16 tracking-tighter leading-tight">
        unleashed by embodied AI.
      </h2>
    </TextGrid>
  );
}