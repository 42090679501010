import { useMemo } from 'react';
import * as THREE from 'three';

export const useParticleMaterial = (texture: THREE.Texture) => {
  return useMemo(() => {
    return new THREE.PointsMaterial({
      size: 0.008,
      map: texture,
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
    });
  }, [texture]);
};