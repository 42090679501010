import React from 'react';
import HeroTitle from './HeroTitle';
import HeroLogo from './HeroLogo';
import HeroSubtitle from './HeroSubtitle';
import { useLogoHover } from './useLogoHover';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface MainContentProps {
  handleLogoHover: (hovering: boolean) => void;
  setIconCenter: (center: { x: number, y: number }) => void;
  isPageLoaded: boolean;
}

export default function MainContent({ handleLogoHover, setIconCenter, isPageLoaded }: MainContentProps) {
  const { handleHover, iconRef, setIconLoaded } = useLogoHover(handleLogoHover, setIconCenter);
  const isMobileOrTablet = useMediaQuery('(max-width: 1023px)');

  return (
    <main className="flex-grow flex flex-col justify-center items-center text-center relative z-20 -mt-12 mobile:-mt-6 tablet:-mt-8 laptop:-mt-12" style={{"--size-reduction": "0.9"}}>
      <HeroTitle />
      <HeroLogo 
        iconRef={iconRef}
        handleHover={handleHover}
        setIconLoaded={setIconLoaded}
        isAnimating={isMobileOrTablet && isPageLoaded}
      />
      <HeroSubtitle />
    </main>
  );
}