import React, { useState } from 'react';
import ThreeParticleSystem from '../ThreeParticleSystem';
import Header from './Header';
import MainContent from './MainContent';
import useParticleSystem from './useParticleSystem';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export default function Hero() {
  const { isLogoHovered, isAnimating, particleKey, handleLogoHover, isPageLoaded } = useParticleSystem();
  const [iconCenter, setIconCenter] = useState({ x: 0, y: 0 });
  const isMobileOrTablet = useMediaQuery('(max-width: 1023px)');

  return (
    <div className="bg-black text-white h-screen font-tt-fors p-8 mobile:p-4 tablet:p-6 laptop:px-[var(--horizontal-padding)] laptop:py-7 desktop:px-[var(--horizontal-padding)] desktop:py-8 flex flex-col relative overflow-hidden">
      <ThreeParticleSystem 
        key={particleKey}
        isVisible={isLogoHovered || (isMobileOrTablet && isPageLoaded)}
        isAnimating={isAnimating}
        iconCenter={iconCenter}
      />
      <Header />
      <MainContent 
        handleLogoHover={handleLogoHover} 
        setIconCenter={setIconCenter}
        isPageLoaded={isPageLoaded}
      />
    </div>
  );
}