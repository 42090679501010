import React, { useEffect } from 'react';
import { useParticleSystem } from './useParticleSystem';
import { ParticleSystemProps } from '../../types';

const ParticleSystem: React.FC<ParticleSystemProps> = ({ isAnimating, iconCenter }) => {
  const { particlesRef, resetParticles } = useParticleSystem(isAnimating, iconCenter);

  useEffect(() => {
    return () => {
      resetParticles();
    };
  }, [resetParticles]);

  return <points ref={particlesRef} />;
};

export default ParticleSystem;