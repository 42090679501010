import { useState, useEffect } from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export default function useParticleSystem() {
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [particleKey, setParticleKey] = useState(0);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const isMobileOrTablet = useMediaQuery('(max-width: 1023px)');

  const handleLogoHover = (hovering: boolean) => {
    if (!isMobileOrTablet) {
      setIsLogoHovered(hovering);
      if (hovering) {
        setIsAnimating(true);
      }
    }
  };

  useEffect(() => {
    if (!isLogoHovered && !isMobileOrTablet) {
      const timer = setTimeout(() => {
        setParticleKey(prevKey => prevKey + 1);
        setIsAnimating(false);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [isLogoHovered, isMobileOrTablet]);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(true);
      if (isMobileOrTablet) {
        setIsAnimating(true);
      }
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    }
  }, [isMobileOrTablet]);

  return { isLogoHovered, isAnimating, particleKey, handleLogoHover, isPageLoaded };
}