import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { MutableRefObject } from 'react';
import { gaussianRandom, getExpansionScalingFactor, FIXED_TIME_STEP, MAX_STEPS } from '../../utils';

export const useParticleAnimation = (
  particlesRef: MutableRefObject<THREE.Points | null>,
  positions: Float32Array,
  velocities: Float32Array,
  isAnimating: boolean,
  iconCenter: { x: number, y: number }
) => {
  useFrame((_, delta) => {
    if (particlesRef.current && isAnimating) {
      const currentPositions = particlesRef.current.geometry.attributes.position.array as Float32Array;
      const currentVelocities = particlesRef.current.geometry.attributes.velocity.array as Float32Array;

      const scalingFactor = getExpansionScalingFactor();

      let steps = 0;
      while (delta > 0 && steps < MAX_STEPS) {
        const stepDelta = Math.min(delta, FIXED_TIME_STEP);
        updateParticles(currentPositions, currentVelocities, scalingFactor, stepDelta, iconCenter);
        delta -= stepDelta;
        steps++;
      }

      particlesRef.current.geometry.attributes.position.needsUpdate = true;
    }
  });
};

function updateParticles(
  positions: Float32Array,
  velocities: Float32Array,
  scalingFactor: number,
  delta: number,
  iconCenter: { x: number, y: number }
) {
  for (let i = 0; i < positions.length; i += 3) {
    positions[i] += velocities[i] * scalingFactor * delta;
    positions[i + 1] += velocities[i + 1] * scalingFactor * delta;
    positions[i + 2] += velocities[i + 2] * scalingFactor * delta;

    velocities[i] += gaussianRandom(0, 0.0001) * delta;
    velocities[i + 1] += gaussianRandom(0, 0.0001) * delta;
    velocities[i + 2] += gaussianRandom(0, 0.0001) * delta;

    const boundary = 2.0;
    for (let j = 0; j < 3; j++) {
      const offset = j === 0 ? iconCenter.x : (j === 1 ? iconCenter.y : 0);
      if (Math.abs(positions[i + j] - offset) > boundary) {
        velocities[i + j] *= -0.9;
        positions[i + j] = Math.sign(positions[i + j] - offset) * boundary + offset;
      }
    }
  }
}