import React from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface HeroLogoProps {
  iconRef: React.RefObject<HTMLImageElement>;
  handleHover: (hovering: boolean) => void;
  setIconLoaded: (loaded: boolean) => void;
  isAnimating: boolean;
}

export default function HeroLogo({ iconRef, handleHover, setIconLoaded, isAnimating }: HeroLogoProps) {
  const isMobileOrTablet = useMediaQuery('(max-width: 1023px)');

  return (
    <div 
      className="mb-16 mobile:mb-8 tablet:mb-12 laptop:mb-16 relative"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <img
        ref={iconRef}
        src="https://createsiteai.b-cdn.net/static_website/tetsuwan_scientific/Full%20Tetsuone%20Logo%20(White)-cropped.svg"
        alt="Tetsuwan Scientific Logo"
        className={`logo-element w-[300px] mobile:w-[200px] tablet:w-[250px] laptop:w-[300px] h-auto mx-auto transition-transform duration-300 ${!isMobileOrTablet ? 'hover:scale-110' : ''} ${isAnimating ? 'scale-110' : ''}`}
        onLoad={() => setIconLoaded(true)}
      />
    </div>
  );
}