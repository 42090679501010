import { useRef, useEffect, useCallback } from 'react';
import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';
import { TEXTURE_URL } from './constants';
import { useParticleGeometry } from './useParticleGeometry';
import { useParticleMaterial } from './useParticleMaterial';
import { useParticleAnimation } from './useParticleAnimation';

export const useParticleSystem = (isAnimating: boolean, iconCenter: { x: number, y: number }) => {
  const particlesRef = useRef<THREE.Points>(null);
  const texture = useLoader(THREE.TextureLoader, TEXTURE_URL);

  const { positions, velocities, geometry, resetGeometry } = useParticleGeometry(iconCenter);
  const material = useParticleMaterial(texture);

  useEffect(() => {
    if (particlesRef.current) {
      particlesRef.current.geometry = geometry;
      particlesRef.current.material = material;
    }
  }, [geometry, material]);

  const resetParticles = useCallback(() => {
    resetGeometry();
    if (particlesRef.current) {
      particlesRef.current.geometry.attributes.position.needsUpdate = true;
      particlesRef.current.geometry.attributes.velocity.needsUpdate = true;
    }
  }, [resetGeometry]);

  useParticleAnimation(particlesRef, positions, velocities, isAnimating, iconCenter);

  return { particlesRef, resetParticles };
};

export * from './types';