import { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export function useLogoHover(
  handleLogoHover: (hovering: boolean) => void,
  setIconCenter: (center: { x: number, y: number }) => void
) {
  const iconRef = useRef<HTMLImageElement>(null);
  const [iconLoaded, setIconLoaded] = useState(false);
  const isMobileOrTablet = useMediaQuery('(max-width: 1023px)');

  useEffect(() => {
    if (iconRef.current && iconLoaded) {
      const rect = iconRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      setIconCenter({ x: centerX / window.innerWidth * 2 - 1, y: -(centerY / window.innerHeight * 2 - 1) });
    }
  }, [iconLoaded, setIconCenter]);

  const handleHover = (hovering: boolean) => {
    if (!isMobileOrTablet) {
      handleLogoHover(hovering);
    }
  };

  return { handleHover, iconRef, setIconLoaded };
}