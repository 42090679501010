import React, { useEffect, useState, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import ParticleSystem from './ParticleSystem';
import { ThreeParticleSystemProps } from './types';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const ThreeParticleSystem: React.FC<ThreeParticleSystemProps> = ({ isVisible, isAnimating, iconCenter }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [canvasOffset, setCanvasOffset] = useState(0);
  const animationStartedRef = useRef(false);

  useEffect(() => {
    if (isMobile && !animationStartedRef.current) {
      const calculateOffset = () => {
        const logoElement = document.querySelector('.logo-element') as HTMLElement;
        if (logoElement) {
          const logoRect = logoElement.getBoundingClientRect();
          const logoCenter = logoRect.top + logoRect.height / 2;
          const viewportCenter = window.innerHeight / 2;
          const offset = logoCenter - viewportCenter;
          setCanvasOffset(offset);
        }
      };

      calculateOffset();
      window.addEventListener('resize', calculateOffset);
      return () => window.removeEventListener('resize', calculateOffset);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isAnimating && isMobile) {
      animationStartedRef.current = true;
    }
  }, [isAnimating, isMobile]);

  const canvasStyle = {
    position: 'absolute' as const,
    top: isMobile ? (animationStartedRef.current ? `${-canvasOffset}px` : `calc(-${canvasOffset}px - 10dvh)`) : '0',
    left: 0,
    width: '100%',
    height: isMobile ? 'calc(100% + 20dvh)' : '100%',
    pointerEvents: 'none' as const,
    zIndex: 10,
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.25s ease-in-out',
  };

  return (
    <Canvas camera={{ position: [0, 0, 1] }} style={canvasStyle}>
      <ParticleSystem isAnimating={isAnimating} iconCenter={iconCenter} />
    </Canvas>
  );
};

export default ThreeParticleSystem;