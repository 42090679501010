import React from 'react';

interface TextGridProps {
  children: React.ReactNode;
}

const TextGrid: React.FC<TextGridProps> = ({ children }) => {
  return (
    <div className="grid grid-cols-1 w-full max-w-5xl mx-auto px-4 mobile:px-2 tablet:px-3 laptop:px-3">
      {children}
    </div>
  );
};

export default TextGrid;