import React from 'react';
import TextGrid from '../../TextGrid';

export default function HeroSubtitle() {
  return (
    <TextGrid>
      <p className="text-[calc(1.875rem+var(--desktop-font-increase)+var(--desktop-font-decrease-unlimited))] mobile:text-xl tablet:text-2xl laptop:text-[calc(1.875rem+var(--desktop-font-increase)+var(--desktop-font-decrease-unlimited))] tracking-tighter leading-tight">
        unlimited scientists, unlimited innovation.
      </p>
    </TextGrid>
  );
}