import { useMemo, useCallback } from 'react';
import * as THREE from 'three';
import { PARTICLE_COUNT } from './constants';
import { gaussianRandom } from '../../utils';
import { ParticleGeometry } from './types';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export const useParticleGeometry = (iconCenter: { x: number, y: number }): ParticleGeometry => {
  const positions = useMemo(() => new Float32Array(PARTICLE_COUNT * 3), []);
  const velocities = useMemo(() => new Float32Array(PARTICLE_COUNT * 3), []);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const initializeParticles = useCallback(() => {
    const scalingFactor = 0.1; // Shrink the initial distribution
    const mobileYOffset = isMobile ? 0.04 : 0; // 20px higher on mobile (assuming 1 unit = 500px)

    for (let i = 0; i < PARTICLE_COUNT; i++) {
      const i3 = i * 3;
      positions[i3] = gaussianRandom(0, 0.1) * scalingFactor + iconCenter.x;
      positions[i3 + 1] = gaussianRandom(0, 0.1) * scalingFactor + iconCenter.y + mobileYOffset;
      positions[i3 + 2] = gaussianRandom(0, 0.1) * scalingFactor;

      velocities[i3] = gaussianRandom(0, 0.01);
      velocities[i3 + 1] = gaussianRandom(0, 0.01);
      velocities[i3 + 2] = gaussianRandom(0, 0.01);
    }
  }, [positions, velocities, iconCenter, isMobile]);

  const geometry = useMemo(() => {
    const geo = new THREE.BufferGeometry();
    initializeParticles();
    geo.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geo.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    return geo;
  }, [positions, velocities, initializeParticles]);

  const resetGeometry = useCallback(() => {
    initializeParticles();
    geometry.attributes.position.needsUpdate = true;
    geometry.attributes.velocity.needsUpdate = true;
  }, [initializeParticles, geometry]);

  return { positions, velocities, geometry, resetGeometry };
};